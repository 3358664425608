import React, { useState } from "react";
import {
  Dialog,
  IconButton,
  DialogContent,
  Button,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAuth from "../../../hooks/useAuth";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const AddDeviceModal = (props) => {
  const { handleClose, openModal, isDeviceModal } = props;
  const axiosPrivate = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const [skuId, setSkuID] = useState("");
  const [skuError, setSkuError] = useState(false);
  const [edgeComponentType, setEdgeComponentType] = useState("");
  const [edgeComponentError, setEdgeComponentError] = useState(false);
  const [cameraBrand, setCameraBrand] = useState("");
  const [cameraBrandError, setCameraBrandError] = useState(false);
  const [cameraModel, setCameraModel] = useState("");
  const [cameraModelError, setCameraModelError] = useState(false);

  const handleCloseModal = () => {
    setSkuID("");
    setCameraBrand("");
    setCameraModel("");
    setEdgeComponentType("");
    setSkuError(false);
    setEdgeComponentError(false);
    setCameraBrandError(false);
    setCameraModelError(false);
    handleClose();
  };

  const addDevices = async (url, body) => {
    const access_token = auth?.access_token;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axiosPrivate.post(url, body, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Time-Zone": `${timeZone}`,
      },
      withCredentials: false,
    });
    setAuth((prev) => {
      return {
        ...prev,
        response: response.data,
      };
    });
  };

  const handleSubmit = async () => {
    setSkuError(false);
    setEdgeComponentError(false);
    setCameraBrandError(false);
    setCameraModelError(false);
    if (skuId == "") {
      setSkuError(true);
    }
    if (edgeComponentType == "") {
      setEdgeComponentError(true);
    }
    if (cameraBrand == "") {
      setCameraBrandError(true);
    }
    if (cameraModel == "") {
      setCameraModelError(true);
    }

    if (isDeviceModal) {
      if (skuId && edgeComponentType) {
        let url = "devices/v1/device_components";
        const body = {
          sku_id: skuId,
          component_type: edgeComponentType,
          additional_info: {},
        };
        addDevices(url, body).then(() => {
          handleCloseModal();
        });
      }
    } else {
      if (skuId && cameraBrand && cameraModel) {
        let url = "devices/v1/cameras";
        const body = {
          sku_id: skuId,
          camera_brand: cameraBrand,
          camera_model: cameraModel,
        };
        addDevices(url, body).then(() => {
          handleCloseModal();
        });
      }
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="md"
      BackdropProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          backdropFilter: "blur(0.8px)",
        },
      }}
      PaperProps={{
        style: {
          overflow: "hidden",
        },
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3
          onClose={handleCloseModal}
          style={{ padding: "10px", marginLeft: "14px" }}
        >
          {isDeviceModal ? "Add New Edge CPU" : "Add New Camera"}
        </h3>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleCloseModal}
          aria-label="close"
          style={{
            width: "24px",
            display: "flex",
            float: "right",
            padding: "25px",
            marginRight: "12px",
            marginTop: "16px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent style={{ height: "300px" }}>
        <form
          noValidate
          autoComplete="off"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            height: "100%",
            justifyContent: "space-between",
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            label="SKU ID"
            variant="outlined"
            required
            onChange={(e) => setSkuID(e.target.value)}
            error={skuError}
          />
          {isDeviceModal ? (
            <>
              <TextField
                label="Component Type"
                variant="outlined"
                onChange={(e) => {
                  setEdgeComponentType(e.target.value);
                }}
                error={edgeComponentError}
                required
              />
              <TextField
                label="Additional Info (Optional)"
                variant="outlined"
              />
            </>
          ) : (
            <>
              <TextField
                label="Camera Brand"
                variant="outlined"
                onChange={(e) => {
                  setCameraBrand(e.target.value);
                }}
                error={cameraBrandError}
                required
              />
              <TextField
                label="Camera Model"
                variant="outlined"
                onChange={(e) => {
                  setCameraModel(e.target.value);
                }}
                error={cameraModelError}
                required
              />
            </>
          )}

          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddDeviceModal;
