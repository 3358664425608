import React, { useEffect, useState } from "react";
import AppBarCommon from "../AppBarCommon";
import EventsModal from "./EventsModal";

const MemberEvents = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const viewRealTime = () => {
    setOpenModal(true);
  };

  return (
    <div>
      <AppBarCommon name="Events" viewRealTime={viewRealTime} viewBtn={true} />
      <EventsModal
        openModal={openModal}
        handleClose={handleCloseModal}
      ></EventsModal>
      Welcome Events!
    </div>
  );
};

export default MemberEvents;
