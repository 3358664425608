import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SOCKET_URL } from "../../api/axios";

import { Dialog, IconButton, DialogContent } from "@mui/material";
import "./EventsModal.css";

const EventsModal = (props) => {
  const [datas, setDatas] = useState(null);
  const [images, setImages] = useState([]);
  const [recentEventsImages, setRecentEventsImages] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [socketConnected, setSocketConnected] = useState(null);
  const [expandEnabled, setExpandEnabled] = useState(false);
  const [recentEventsSelected, setRecentEventsSelected] = useState(false);
  const [connectAgain, SetConnectAgain] = useState(props.openModal);
  const [recentevents, setRecentEvents] = useState([]);
  const [socketDisconnected, setSocketDisconnected] = useState(false);
  const [isRecentLoading, setRecentLoading] = useState(true);
  const [recentDatas, setRecentDatas] = useState(null);
  const [recentEventsID, setRecentEventsId] = useState("");

  let socket = null;

  useEffect(() => {
    if (recentDatas) {
      let eventList = Object.assign([], recentevents);
      let existEventIndex = eventList.findIndex(
        (item) => item.detection_id === recentDatas.detection_id
      );
      if (existEventIndex == -1) {
        eventList.unshift(recentDatas);
      } else {
        let eventData = eventList[existEventIndex].data;
        let connectedData = eventData.concat(recentDatas.data);
        eventList[existEventIndex].data = connectedData;
      }
      setRecentEvents(eventList);
    }
  }, [recentDatas]);

  useEffect(() => {
    if (socketDisconnected) {
      onDisconnectSocket();
      setSocketDisconnected(false);
    }
  }, [socketDisconnected]);

  const onDisconnectSocket = () => {
    console.log("socketDisconnect", socket, socketConnected);
    if (!socket) socket = socketConnected;
    if (socket) {
      setSocketConnected(null);
      setImages([]);
      setLoading(true);
      setDatas([]);
      if (connectAgain) {
        console.log("ConnectingAgain");
        socket.connect();
      } else {
        console.log("Disconnecting");
        setRecentEvents([]);
        socket.disconnect();
      }
    }
  };

  const handleOpenConnection = () => {
    socket = io.connect(SOCKET_URL, {
      reconnection: false,
    });
    socket.on("connect", () => {
      console.log("socket", socket);
      setSocketConnected(socket);
    });
    socket.on("disconnect", () => {
      console.log("socketdisconnected");
      setSocketDisconnected(true);
    });
    socket.on("detections", (data) => {
      console.log("socketData", data);
      if (data.hasOwnProperty("is_event_started")) {
        if (data?.is_event_started) {
          setImages([]);
          setLoading(false);
          setDatas(data);
        } else {
          setDatas(data);
          setRecentDatas(data);
          setImages((prev) => [...prev, ...data.data]);
          setLoading(false);
        }
      }
    });
  };

  useEffect(() => {
    SetConnectAgain(props.openModal);
    if (props.openModal) {
      handleOpenConnection();
      setRecentEventsSelected(false);
    }
  }, [props.openModal]);

  useEffect(() => {
    return () => {
      if (socketConnected) {
        socketConnected.disconnect();
      }
    };
  }, []);

  const handleCloseModal = () => {
    SetConnectAgain(false);
    if (expandEnabled) {
      document.exitFullscreen();
      setExpandEnabled(false);
    }
    setRecentEventsSelected(false);
    setImages([]);
    setRecentEventsImages([]);
    props.handleClose();
    if (socketConnected) {
      socketConnected.disconnect();
    }
  };

  const handleExpand = () => {
    if (!expandEnabled) {
      setExpandEnabled(true);
      document.body.requestFullscreen();
    } else {
      setExpandEnabled(false);
      document.exitFullscreen();
    }
  };

  const handleRecentEvents = (item) => {
    setRecentEventsId(item.detection_id);
    setRecentEventsSelected(true);
    setRecentEventsImages([]);
    setRecentLoading(true);
    const images = item.data;
    setTimeout(() => {
      setRecentEventsImages(images);
      setRecentLoading(false);
    }, 2000);
  };

  const recentBack = () => {
    setRecentEventsId("");
    setRecentEventsImages([]);
    setLoading(true);
    setRecentEventsSelected(false);
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={handleCloseModal}
      fullScreen={expandEnabled}
      fullWidth
      maxWidth="lg"
      BackdropProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          backdropFilter: "blur(1.5px)",
        },
      }}
      PaperProps={{
        style: {
          overflow: "hidden",
          minHeight: "600px",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#3f3f3f",
        }}
      >
        <div></div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            marginLeft: "12px",
          }}
        >
          {recentEventsSelected && (
            <ArrowBackIcon
              style={{ cursor: "pointer", width: "24px", color: "white" }}
              onClick={recentBack}
            />
          )}
          <h3
            onClose={handleCloseModal}
            style={{
              padding: "10px",
              color: "white",
              flex: 1,
              textAlign: "center",
            }}
          >
            {recentEventsSelected
              ? `Recent Event : ${recentEventsID}`
              : "Real Time Events"}
          </h3>
        </div>
        <div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleExpand}
            aria-label="close"
            style={{
              width: "24px",
              display: "flex",
              float: "left",
              padding: "25px",
              marginRight: "12px",
              marginTop: "16px",
              color: "white",
            }}
          >
            {expandEnabled ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
          </IconButton>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{
              width: "24px",
              display: "flex",
              float: "right",
              padding: "25px",
              marginRight: "12px",
              marginTop: "16px",
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <DialogContent style={{ display: "flex", padding: "0px" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <div
            style={{
              width: "15%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              background: "rgb(235 235 235)",
              padding: "5px",
            }}
          >
            <h4
              style={{
                textAlign: "center",
                borderBottom: "1px ridge #dad6d6",
                margin: "0px",
                padding: "16px",
              }}
            >
              Recent events
            </h4>
            <ul
              id="recent-events-scroll-container"
              style={{ overflowY: "scroll", padding: "0px", margin: "0px" }}
            >
              {recentevents.map((item) => {
                return (
                  <li
                    className={
                      item.detection_id === recentEventsID
                        ? "recentEvents-list-selected"
                        : "recentEvents-list"
                    }
                    onClick={() => handleRecentEvents(item)}
                    // style={{
                    //   backgroundColor:
                    //     item.detection_id === recentEventsID
                    //       ? "blue"
                    //       : "#d8d7d7",
                    // }}
                  >
                    {item.detection_id}
                  </li>
                );
              })}
            </ul>
          </div>
          {!recentEventsSelected ? (
            <div
              style={{
                display: "flex",
                width: "85%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "360px",
                    flexDirection: "column",
                  }}
                >
                  <CircularProgress />
                  <h5>Waiting For Events</h5>
                </div>
              ) : (
                <>
                  {!datas.is_event_started ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {images?.map((item, index) => {
                        return (
                          <div
                            style={{
                              width: "50%",
                              height: "50%",
                              position: "relative",
                            }}
                          >
                            <img
                              src={item.url}
                              key={item.url}
                              style={{
                                padding: "10px",
                                borderRadius: "5px",
                                display: "block",
                                height: "100%",
                                width: "100%",
                                objectFit: "cover",
                              }}
                              alt=""
                            />
                            {item?.is_valid ? (
                              <CheckCircleOutlineIcon
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "18px",
                                  fontSize: "30px",
                                  color: "green",
                                  background: "white",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <CancelOutlinedIcon
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "18px",
                                  fontSize: "30px",
                                  color: "red",
                                  background: "white",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100%",
                        height: "360px",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                      <div>Events started : {datas.detection_id}</div>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {isRecentLoading ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "360px",
                    flexDirection: "column",
                  }}
                >
                  <CircularProgress />
                  <h5>Loading Recent Events</h5>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      flexWrap: "wrap",
                    }}
                  >
                    {recentEventsImages?.map((item, index) => {
                      return (
                        <div
                          style={{
                            width: "50%",
                            height: "50%",
                            position: "relative",
                          }}
                        >
                          <img
                            width="98%"
                            src={item.url}
                            key={item.url}
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              display: "block",
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            alt=""
                          />
                          {item?.is_valid ? (
                            <CheckCircleOutlineIcon
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "18px",
                                fontSize: "30px",
                                color: "green",
                                background: "white",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            <CancelOutlinedIcon
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "18px",
                                fontSize: "30px",
                                color: "red",
                                background: "white",
                                borderRadius: "50%",
                              }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EventsModal;
