import React, { useState, useEffect } from "react";
import "./AdminPage.css";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import LogoutIcon from "@mui/icons-material/Logout";
import user_profile from "../../assets/user_profile.svg";
import logo_blue from "../../assets/logo_blue.svg";
import HomeIcon from "@mui/icons-material/Home";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import ListIcon from "@mui/icons-material/List";
import AddIcon from "@mui/icons-material/Add";

const drawerWidth = 260;

function AdminPage(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openAccess, setOpenAccess] = useState(true);
  const [openControl, setOpenControl] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();

  const logout = useLogout();

  const logoutHandler = async () => {
    await logout();
    navigate("/");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = useNavigate();
  let params = useParams();

  useEffect(() => {
    let url = Object.values(params)[0];

    if (url.includes("home")) {
      setSelectedIndex(0);
    }
    if (url.includes("add_events")) {
      setSelectedIndex(1);
    }
  }, []);

  const handleAccessClick = () => {
    if (openControl && openAccess) {
      setOpenAccess(!openAccess);
    } else if (!openControl && openAccess) {
      setOpenAccess(!openAccess);
      setOpenControl(true);
    } else setOpenAccess(true);
  };

  const handleControlClick = () => {
    if (openControl && openAccess) {
      setOpenControl(!openControl);
    } else if (openControl && !openAccess) {
      setOpenControl(!openControl);
      setOpenAccess(true);
    } else setOpenControl(true);
  };

  const drawer = (
    <div>
      <Toolbar style={{ paddingLeft: "12px", paddingBottom: "10px" }}>
        <div className="home">
          <img
            src={logo_blue}
            alt=""
            height="60px"
            width="180px"
            className="logo_image"
          />
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
          >
            <img
              className="profile"
              src={user_profile}
              alt=""
              width="60px"
              height="60px"
              style={{ marginTop: "14px", marginRight: "14px", padding: "0px" }}
            />
            <label>
              <h3 style={{ marginBottom: "0px" }}>
                <strong style={{ textTransform: "capitalize" }}>
                  {localStorage.getItem("user_name")}
                </strong>
              </h3>
              <p style={{ marginTop: "0px" }}>{"Admin"}</p>
            </label>
          </div>
        </div>
      </Toolbar>
      <Divider />
      <List disablePadding={true}>
        <ListItemButton
          style={{
            background: selectedIndex === 0 ? "#21b3d8" : "",
            color: selectedIndex === 0 ? "white" : "",
          }}
          onClick={() => {
            navigate("home");
            setSelectedIndex(0);
          }}
        >
          <ListItemIcon
            style={{
              color: selectedIndex === 0 ? "white" : "",
            }}
          >
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </List>
      <List disablePadding={true}>
        <ListItemButton
          style={{
            background: selectedIndex === 1 ? "#21b3d8" : "",
            color: selectedIndex === 1 ? "white" : "",
          }}
          onClick={() => {
            navigate("add_events");
            setSelectedIndex(1);
          }}
        >
          <ListItemIcon
            style={{
              color: selectedIndex === 1 ? "white" : "",
            }}
          >
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Events" />
        </ListItemButton>
      </List>
      {/* <List disablePadding={true}>
        <ListItemButton onClick={() => navigate("devices")}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Devices" />
        </ListItemButton>
      </List> */}

      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={logoutHandler}>
            <ListItemIcon style={{ color: "red" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" style={{ color: "red" }} />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          style={{ paddingLeft: "8px" }}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        style={{ marginTop: "50px" }}
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Outlet />
        <Toolbar />
      </Box>
    </Box>
  );
}

AdminPage.propTypes = {
  window: PropTypes.func,
};

export default AdminPage;
