import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import AppBarCommon from "./AppBarCommon";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Settings = () => {
  return (
    <>
      <AppBarCommon name="Settings" />
      <Grid container spacing={2} columns={16} style={{ paddingTop: "10px" }}>
        <Grid item xs={8}>
          <Item style={{ height: "47px" }}>
            <Checkbox {...label} />
            <span style={{ fontSize: "medium", color: "black" }}>
              Enable Presentation Attack Detection
            </span>
          </Item>
        </Grid>
        <Grid item xs={8}>
          <Item style={{ height: "47px" }}>
            <Checkbox {...label} />
            <span style={{ fontSize: "medium", color: "black" }}>
              Enable Email Notification
            </span>
          </Item>
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
