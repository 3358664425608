import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Button } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import AppBarCommon from "../../AppBarCommon";
import AddDeviceModal from "./AddDeviceModal";
import { BASE_URL } from "../../../api/axios";

const RegisterDevices = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isDeviceModal, setIsDeviceModal] = useState(false);
  const [deviceComponent, setDeviceComponent] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [camera1SelectedId, setcamera1ID] = useState("");
  const [camera2SelectedId, setcamera2ID] = useState("");
  const [edgeCPUSelectedId, setEdgeCPUID] = useState("");
  const { auth, setAuth } = useAuth();
  const [edgeFieldValid, setedgeFieldValid] = useState(true);
  const [camOneFieldValid, setCamOneFieldValid] = useState(true);
  const [camTwoFieldValid, setCamTwoFieldValid] = useState(true);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  useEffect(() => {
    if (!openModal) {
      fetchDevices();
    }
  }, [openModal]);

  const fetchDevices = () => {
    let edgeUrl = `${BASE_URL}/devices/v1/device_components`;
    let camUrl = `${BASE_URL}/devices/v1/cameras`;

    getDevices(edgeUrl).then((data) => {
      setDeviceComponent(data.device_components);
    });
    getDevices(camUrl).then((data) => {
      setCameras(data.cameras);
    });
  };

  const controller = new AbortController();
  const getDevices = async (url) => {
    try {
      const userResponse = await axiosPrivate.get(url, {
        signal: controller.signal,
      });
      return userResponse.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleCamOneChange = (e) => {
    const {
      target: { value },
    } = e;
    setCamOneFieldValid(true);
    setcamera1ID(value.camera_id);
  };

  const handleCamTwoChange = (e) => {
    const {
      target: { value },
    } = e;
    setCamTwoFieldValid(true);
    setcamera2ID(value.camera_id);
  };

  const handleEdgeCPUChange = (e) => {
    const {
      target: { value },
    } = e;
    setedgeFieldValid(true);
    setEdgeCPUID(value.component_id);
  };

  const handleSubmit = async () => {
    const access_token = auth?.access_token;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setedgeFieldValid(true);
    setCamOneFieldValid(true);
    setCamTwoFieldValid(true);

    const devices = {
      edge_cpu: edgeCPUSelectedId,
      camera1: camera1SelectedId,
      camera2: camera2SelectedId,
    };

    if (edgeCPUSelectedId == "") {
      setedgeFieldValid(false);
    }
    if (camera1SelectedId == "") {
      setCamOneFieldValid(false);
    }
    if (camera2SelectedId == "") {
      setCamTwoFieldValid(false);
    }

    if (
      edgeCPUSelectedId !== "" &&
      camera1SelectedId !== "" &&
      camera2SelectedId !== ""
    ) {
      const response = await axiosPrivate.post("/devices/v1", devices, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Time-Zone": `${timeZone}`,
        },
        withCredentials: false,
      });
      setAuth((prev) => {
        return {
          ...prev,
          response: response.data,
        };
      });
      setcamera1ID("");
      setcamera1ID("");
      setEdgeCPUID("");
      navigate(-1);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeviceModal = (type) => {
    if (type === "edge") {
      setIsDeviceModal(true);
      setOpenModal(true);
    } else {
      setIsDeviceModal(false);
      setOpenModal(true);
    }
  };

  return (
    <div>
      <AppBarCommon name="Register Devices" backButton={true} />

      <AddDeviceModal
        openModal={openModal}
        handleClose={handleCloseModal}
        isDeviceModal={isDeviceModal}
      />
      <div
        style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}
      >
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label" variant="outlined">
            Edge CPU
          </InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            onChange={(e) => handleEdgeCPUChange(e)}
            input={<OutlinedInput label="Edge CPU" />}
            MenuProps={MenuProps}
            error={!edgeFieldValid}
          >
            <MenuItem key={"edge"} onClick={() => handleDeviceModal("edge")}>
              + Add Edge CPU
            </MenuItem>
            {deviceComponent?.map((item) => {
              return (
                <MenuItem key={item.component_id} value={item}>
                  {item.component_id}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Camera 1</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            onChange={(e) => handleCamOneChange(e)}
            input={<OutlinedInput label="Camera 1" />}
            MenuProps={MenuProps}
            error={!camOneFieldValid}
          >
            <MenuItem
              key={"camera"}
              onClick={() => handleDeviceModal("camera")}
            >
              + Add Camera
            </MenuItem>
            {cameras?.map((item) => {
              return (
                <MenuItem
                  key={item.camera_id}
                  value={item}
                  disabled={item.camera_id === camera2SelectedId ? true : false}
                >
                  {item.camera_id}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="demo-multiple-name-label">Camera 2</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            onChange={(e) => handleCamTwoChange(e)}
            input={<OutlinedInput label="Camera 2" />}
            MenuProps={MenuProps}
            error={!camTwoFieldValid}
          >
            <MenuItem onClick={() => handleDeviceModal("camera")}>
              + Add Camera
            </MenuItem>
            {cameras?.map((item) => {
              return (
                <MenuItem
                  key={item.camera_id}
                  value={item}
                  disabled={item.camera_id === camera1SelectedId ? true : false}
                >
                  {item.camera_id}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          style={{ width: "180px", margin: "25px" }}
          variant="contained"
          onClick={handleSubmit}
        >
          Register
        </Button>
      </div>
    </div>
  );
};

export default RegisterDevices;
