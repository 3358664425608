import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const refresh_token = window.localStorage.getItem("refresh_token");
    const token = {
      token: refresh_token,
    };
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const response = await axios.post("api/auth/v1/token/refresh", token, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${refresh_token}`,
        "Time-Zone": `${timeZone}`,
      },
      withCredentials: false,
    });
    setAuth((prev) => {
      return {
        ...prev,
        access_token: response.data.access_token,
      };
    });
    return response.data.access_token;
  };
  return refresh;
};

export default useRefreshToken;
