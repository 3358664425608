import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Divider, Toolbar, Typography } from "@mui/material";
import "./HomePage.css";
import AppBarCommon from "./AppBarCommon";
import Card from "./Card";
import { Bar, Pie } from "react-chartjs-2";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const HomePage = () => {
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false);
  // const hasAdminRole = localStorage.getItem("user_name") === "admin";
  const axiosPrivate = useAxiosPrivate();

  const hashCode = (str) => {
    // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash * 600;
  };

  const intToRGB = (i) => {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosPrivate.get(
          "/defect-inspection/v1/overview_report"
        );

        const mapData = response.data.recent_events_report.dates;

        const chartDataSet = response.data.recent_events_report.sessions.map(
          (item) => {
            return {
              label: item.name,
              data: item.event_counts,
              backgroundColor: `#${intToRGB(hashCode(item.name))}`,
              bordreColor: `#${intToRGB(hashCode(item.name))}`,
              borderWidth: 1,
            };
          }
        );
        setChartData({
          labels: mapData,
          datasets: chartDataSet,
        });
        setIsHomePage(true);
        setIsLoading(true);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, []);

  return (
    <>
      <AppBarCommon name="Home" />
      {isLoading && (
        <div>
          <Card className="bar__chart">
            <Toolbar>
              <Typography variant="h6">Recent events per order</Typography>
            </Toolbar>
            <Divider />
            <Bar
              className="bar_chart_content"
              data={chartData}
              options={{
                plugins: {
                  title: {
                    display: true,
                  },
                  legend: {
                    display: true,
                    position: "top",
                  },
                },
                scales: {
                  y: {
                    title: {
                      display: true,
                      text: "Count",
                    },
                  },
                  x: {
                    title: {
                      display: true,
                      text: "Date",
                    },
                  },
                },
              }}
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default HomePage;
