import axios from "../api/axios";
import useAuth from "./useAuth";

const useLogout = () => {
  const { setAuth } = useAuth();

  const logout = async () => {
    const access_token = window.localStorage.getItem("access_token");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      const response = await axios.post("/api/auth/v1/logout", access_token, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
          "Time-Zone": `${timeZone}`,
        },
        withCredentials: false,
      });
      console.log(response);
    } catch (err) {
      console.log(err);
    }
    setAuth({});
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("user_name");
  };

  return logout;
};

export default useLogout;
