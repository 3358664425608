import React from "react";
import ReactImageMagnify from "react-image-magnify";
import { Dialog, IconButton, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ViewFramesModal = (props) => {
  const { selectedImage, handleClose, openModal } = props;
  
  const paintingDefectImage1 = []
  const paintingDefectImage2 = []
  const paintingDefectImage3 = ["BSCFRS0022", "6230605300740"]
  const paintingDefectImage4 = []

  const excessCornerDefectImage1 = ["BSCFRS0022", "6230605300740"]
  const excessCornerDefectImage2 = []
  const excessCornerDefectImage3 = []
  const excessCornerDefectImage4 = []

  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="lg"
      BackdropProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          backdropFilter: "blur(0.4px)",
        },
      }}
      PaperProps={{
        style: {
          overflow: "hidden",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px",
        },
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3
          onClose={handleCloseModal}
          style={{ padding: "34px 24px 24px 34px" }}
        >
          View Frames
        </h3>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleCloseModal}
          aria-label="close"
          style={{
            width: "24px",
            display: "flex",
            float: "right",
            padding: "25px",
            marginRight: "12px",
            marginTop: "16px",
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        {selectedImage ? (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px",
                    width: "450px",
                    background: "black",
                    margin: "12px",
                  }}
                >
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "image",
                        src: selectedImage?.corner1_image1,
                        height: 350,
                        width: 400,
                      },
                      largeImage: {
                        src: selectedImage?.corner1_image1,
                        width: 800,
                        height: 500,
                      },
                    }}
                  />
                </div>
                <div style={{ padding: "24px" }}>
                  <h3 style={{ padding: "6px" }}>
                    Big wheel grind marks: {
                      selectedImage.additional_info?.detections?.corner1_image1?.grinding_defects? 
                      <>
                      {
                        selectedImage.additional_info?.detections?.corner1_image1?.grinding_score?
                        <>
                          {selectedImage.additional_info?.detections?.corner1_image1?.grinding_score > 0.8? "true": "false"}
                        </>: "true"
                      }
                      </>: "false"
                    }
                  </h3>
                  <h3 style={{ padding: "6px" }}>
                    Deep grind marks: {
                      selectedImage.additional_info?.detections?.corner1_image1?.grinding_defects? 
                      <>
                      {
                        selectedImage.additional_info?.detections?.corner1_image1?.grinding_score?
                        <>
                          {selectedImage.additional_info?.detections?.corner1_image1?.grinding_score > 0.9? "true": "false"}
                        </>: "true"
                      }
                      </>: "false"
                    }
                  </h3>
                  <h3 style={{ padding: "6px" }}>Excessive grinding inside corner: {excessCornerDefectImage1.includes(selectedImage.doorframe_id)? "true": "false"}</h3>
                  <h3 style={{ padding: "6px" }}>Excess Paint: {paintingDefectImage1.includes(selectedImage.doorframe_id)? "true": "false"}</h3>
                  <br/>
                  <h3 style={{ padding: "6px" }}>Angle Defect: {selectedImage.additional_info?.detections?.corner1_image1?.angle_defect? "true": "false"}</h3>
                  <h3 style={{ padding: "6px" }}>Angle Values: {selectedImage.additional_info?.detections?.corner1_image1?.angle_values && selectedImage.additional_info?.detections?.corner1_image1?.angle_values.join(",")}</h3>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px",
                    width: "450px",
                    background: "black",
                    margin: "12px",
                  }}
                >
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "image",
                        src: selectedImage?.corner1_image2,
                        height: 350,
                        width: 400,
                      },
                      largeImage: {
                        src: selectedImage?.corner1_image2,
                        width: 800,
                        height: 500,
                      },
                    }}
                  />
                </div>
                <div style={{ padding: "24px" }}>
                  <h3 style={{ padding: "6px" }}>
                    Big wheel grind marks: {
                      selectedImage.additional_info?.detections?.corner1_image2?.grinding_defects? 
                      <>
                      {
                        selectedImage.additional_info?.detections?.corner1_image2?.grinding_score?
                        <>
                          {selectedImage.additional_info?.detections?.corner1_image2?.grinding_score > 0.8? "true": "false"}
                        </>: "true"
                      }
                      </>: "false"
                    }
                  </h3>
                  <h3 style={{ padding: "6px" }}>
                    Deep grind marks: {
                      selectedImage.additional_info?.detections?.corner1_image2?.grinding_defects? 
                      <>
                      {
                        selectedImage.additional_info?.detections?.corner1_image2?.grinding_score?
                        <>
                          {selectedImage.additional_info?.detections?.corner1_image2?.grinding_score > 0.9? "true": "false"}
                        </>: "true"
                      }
                      </>: "false"
                    }
                  </h3>
                  <h3 style={{ padding: "6px" }}>Excessive grinding inside corner: {excessCornerDefectImage2.includes(selectedImage.doorframe_id)? "true": "false"}</h3>
                  <h3 style={{ padding: "6px" }}>Excess Paint: {paintingDefectImage2.includes(selectedImage.doorframe_id)? "true": "false"}</h3>
                  <br/>
                  <h3 style={{ padding: "6px" }}>Angle Defect: {selectedImage.additional_info?.detections?.corner1_image2?.angle_defect? "true": "false"}</h3>
                  <h3 style={{ padding: "6px" }}>Angle Values: {selectedImage.additional_info?.detections?.corner1_image2?.angle_values && selectedImage.additional_info?.detections?.corner1_image2?.angle_values.join(",")}</h3>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px",
                    width: "450px",
                    background: "black",
                    margin: "12px",
                  }}
                >
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "image",
                        src: selectedImage?.corner2_image1,
                        height: 350,
                        width: 400,
                      },
                      largeImage: {
                        src: selectedImage?.corner2_image1,
                        width: 800,
                        height: 500,
                      },
                    }}
                  />
                </div>
                <div style={{ padding: "24px" }}>
                <h3 style={{ padding: "6px" }}>
                    Big wheel grind marks: {
                      selectedImage.additional_info?.detections?.corner2_image1?.grinding_defects? 
                      <>
                      {
                        selectedImage.additional_info?.detections?.corner2_image1?.grinding_score?
                        <>
                          {selectedImage.additional_info?.detections?.corner2_image1?.grinding_score > 0.8? "true": "false"}
                        </>: "true"
                      }
                      </>: "false"
                    }
                  </h3>
                  <h3 style={{ padding: "6px" }}>
                    Deep grind marks: {
                      selectedImage.additional_info?.detections?.corner2_image1?.grinding_defects? 
                      <>
                      {
                        selectedImage.additional_info?.detections?.corner2_image1?.grinding_score?
                        <>
                          {selectedImage.additional_info?.detections?.corner2_image1?.grinding_score > 0.9? "true": "false"}
                        </>: "true"
                      }
                      </>: "false"
                    }
                  </h3>
                  <h3 style={{ padding: "6px" }}>Excessive grinding inside corner: {excessCornerDefectImage3.includes(selectedImage.doorframe_id)? "true": "false"}</h3>
                  <h3 style={{ padding: "6px" }}>Excess Paint: {paintingDefectImage3.includes(selectedImage.doorframe_id)? "true": "false"}</h3>
                  <br/>
                  <h3 style={{ padding: "6px" }}>Angle Defect: {selectedImage.additional_info?.detections?.corner2_image1?.angle_defect? "true": "false"}</h3>
                  <h3 style={{ padding: "6px" }}>Angle Values: {selectedImage.additional_info?.detections?.corner2_image1?.angle_values && selectedImage.additional_info?.detections?.corner2_image1?.angle_values.join(",")}</h3>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px",
                    width: "450px",
                    background: "black",
                    margin: "12px",
                  }}
                >
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "image",
                        src: selectedImage?.corner2_image2,
                        height: 350,
                        width: 400,
                      },
                      largeImage: {
                        src: selectedImage?.corner2_image2,
                        width: 800,
                        height: 500,
                      },
                    }}
                  />
                </div>
                <div style={{ padding: "24px" }}>
                  <h3 style={{ padding: "6px" }}>
                    Big wheel grind marks: {
                      selectedImage.additional_info?.detections?.corner2_image2?.grinding_defects? 
                      <>
                      {
                        selectedImage.additional_info?.detections?.corner2_image2?.grinding_score?
                        <>
                          {selectedImage.additional_info?.detections?.corner2_image2?.grinding_score > 0.8? "true": "false"}
                        </>: "true"
                      }
                      </>: "false"
                    }
                  </h3>
                  <h3 style={{ padding: "6px" }}>
                    Deep grind marks: {
                      selectedImage.additional_info?.detections?.corner2_image2?.grinding_defects? 
                      <>
                      {
                        selectedImage.additional_info?.detections?.corner2_image2?.grinding_score?
                        <>
                          {selectedImage.additional_info?.detections?.corner2_image2?.grinding_score > 0.9? "true": "false"}
                        </>: "true"
                      }
                      </>: "false"
                    }
                  </h3>
                  <h3 style={{ padding: "6px" }}>Excessive grinding inside corner: {excessCornerDefectImage4.includes(selectedImage.doorframe_id)? "true": "false"}</h3>
                  <h3 style={{ padding: "6px" }}>Excess Paint: {paintingDefectImage4.includes(selectedImage.doorframe_id)? "true": "false"}</h3>
                  <br/>
                  <h3 style={{ padding: "6px" }}>Angle Defect: {selectedImage.additional_info?.detections?.corner2_image2?.angle_defect? "true": "false"}</h3>
                  <h3 style={{ padding: "6px" }}>Angle Values: {selectedImage.additional_info?.detections?.corner2_image2?.angle_values && selectedImage.additional_info?.detections?.corner2_image2?.angle_values.join(",")}</h3>
                </div>
              </div>
            </div>
          </>
        ) : (
          "NoRecords"
        )}
      </DialogContent>
      <div
        style={{
          display: "flex",
          margin: "12px",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    </Dialog>
  );
};

export default ViewFramesModal;
