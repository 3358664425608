import React, { useState } from "react";
import { AppBar, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Toolbar, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const AppBarCommon = (props) => {
  const { name, drawerToggle } = props;
  const drawerWidth = 260;
  const navigate = useNavigate();

  return (
    <>
      <AppBar
        position="fixed"
        elevation={1}
        boxshadow={0}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{ backgroundColor: "white" }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          {drawerToggle && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={props.handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {props.backButton && (
              <Button style={{ width: "56px" }} onClick={() => navigate(-1)}>
                <ArrowBackIcon
                  style={{ color: "black", marginRight: "20px" }}
                />
              </Button>
            )}
            <Typography
              style={{
                fontFamily: "sans-serif",
                color: "rgb(94 89 89)",
                fontWeight: 600,
                fontSize: "18px",
              }}
              noWrap
              component="div"
            >
              {name}
            </Typography>
          </div>
          {props.viewBtn && (
            <div style={{ display: "flex" }}>
              {/* <Button
                style={{
                  width: "150px",
                  fontSize: "12px",
                  borderRadius: "10px",
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
                onClick={props.viewRealTimeEvents}
                variant="contained"
              >
                View Real Time Events
              </Button> */}
              <Button
                style={props.miniMize? {
                  marginLeft: "12px",
                  width: "150px",
                  fontSize: "12px",
                  borderRadius: "10px",
                  backgroundColor: "#f57c00",
                  color: "#fff",
                } : {
                  marginLeft: "12px",
                  width: "150px",
                  fontSize: "12px",
                  borderRadius: "10px",
                  backgroundColor: "#1976d2",
                  color: "#fff",
                }}
                onClick={props.viewRealTime}
                variant="contained"
              >
                {props.miniMize ? "Continue With Order" : "Start New Order"}
              </Button>
            </div>
          )}
          {props.registerDevice && (
            <Button
              style={{
                width: "150px",
                fontSize: "12px",
                borderRadius: "10px",
                backgroundColor: "#1976d2",
                color: "#fff",
              }}
              onClick={() => navigate("register-device")}
              variant="contained"
            >
              Register New Device
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default AppBarCommon;
