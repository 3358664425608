import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import CloseIcon from "@mui/icons-material/Close";
import HorizontalRule from "@mui/icons-material/HorizontalRule";
import CircularProgress from "@mui/material/CircularProgress";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { SOCKET_URL } from "../../api/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { Snackbar } from "@mui/material";
import {
  Dialog,
  IconButton,
  DialogContent,
  Button,
  TextField,
} from "@mui/material";
import "./EventsModal.css";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RealEventsModal = (props) => {
  const [datas, setDatas] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [socketConnected, setSocketConnected] = useState(null);
  const [expandEnabled, setExpandEnabled] = useState(false);
  const [connectAgain, setConnectAgain] = useState(props.openModal);
  const [socketDisconnected, setSocketDisconnected] = useState(false);
  const [dataV2Events, setDataV2Events] = useState({});
  const [minimizeModalState, setMinimizeModalState] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState("white");
  const [iconColor, setIconColor] = useState("black");
  const [textColor, setTextColor] = useState("black");
  const [orderIDPage, setOrderIDPage] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [orderId, setOrderId] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const { auth, setAuth } = useAuth();
  const [openSnack, setOpenSnack] = useState(false);
  const [errorSnack, setErrorSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [orderLoading, setOrderLoading] = useState(false);
  const [stopOrder, setStopOrder] = useState(false);
  const [isFrameScanned, setFrameScanned] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [connectionAttempt, setConnectionAttempt] = useState(0);
  const [connectionCheckInterval, setConnectionCheckInterval] = useState(-1);
  const [syncEventMessageLogInterval, setSyncEventMessageLogInterval] =
    useState(-1);

  let socket = null;

  useEffect(() => {
    if (socketDisconnected) {
      onDisconnectSocket();
      setSocketDisconnected(false);
    }
  }, [socketDisconnected]);

  useEffect(() => {
    if (dataV2Events.defective != null) {
      if (dataV2Events.defective) {
        setBackgroundColor("red");
      } else {
        setBackgroundColor("green");
      }
      setIconColor("white");
    } else {
      setBackgroundColor("white");
      setIconColor("black");
    }
  }, [dataV2Events]);

  const onDisconnectSocket = () => {
    console.log("socketDisconnect", socket, socketConnected);
    if (!socket) socket = socketConnected;
    if (socket) {
      setSocketConnected(null);
      // setLoading(true);
      // setDatas([]);
      if (connectAgain) {
        console.log("ConnectingAgain");
        socket.connect();
      } else {
        console.log("Disconnecting");
        socket.disconnect();
      }
    }
  };

  const handleOpenConnection = () => {
    if (!socket) {
      console.log("Connecting socket");
      socket = io.connect(SOCKET_URL, {
        reconnection: false,
      });
      console.log("connect", socket);
      socket.on("connect", () => {
        console.log("socket", socket);
        setSocketConnected(socket);
        setConnectionAttempt(0);
      });
      socket.on("disconnect", () => {
        console.log("socketdisconnected");
        setSocketDisconnected(true);
      });
      socket.on("detections_notification", (data) => {
        console.log("socketData", data);
      });

      socket.on("detections", (data) => {
        console.log("socketData", data);
        if (data.hasOwnProperty("is_event_started")) {
          if (data?.is_frame_scanned) {
            setDatas(data);
            setFrameScanned(true);
            if (data?.is_event_started) {
              let datav2 = data.datav2;
              if (Object.keys(datav2).includes("defective")) {
                setDataV2Events(data.datav2);
                setLoading(false);
              } else {
                setLoading(true);
                setDataV2Events({});
              }
            } else {
              setLoading(true);
              setDataV2Events({});
            }
          } else {
            setDatas(data);
            setDataV2Events({});
            setFrameScanned(false);
          }
        } else {
          setDataV2Events({});
        }
      });

      setConnectionCheckInterval(
        setInterval(() => {
          if (socket && !socket.connected) {
            setTimeout(() => {
              if (connectionCheckInterval) {
                console.log("ConnectingAgain after interval");
                socket.connect();
                if (connectionAttempt + 1 <= 5) {
                  setConnectionAttempt(connectionAttempt + 1);
                }
              }
            }, connectionAttempt * 1000);
          }
        }, 5000)
      );

      syncEventLogMessage();
      setSyncEventMessageLogInterval(
        setInterval(() => {
          syncEventLogMessage();
        }, 10000)
      );
    }
  };

  const detectOrder = async () => {
    const response = await axiosPrivate.get("/defect-inspection/v1/detect");
    return response;
  };

  useEffect(() => {
    if (props.openModal) {
      if (!minimizeModalState) {
        setOrderLoading(true);
        detectOrder().then((response) => {
          if (response.status === 200) {
            setOrderNumber(response.data?.order_number);
            setOrderLoading(false);
            setOrderId(response.data.order_number);
            handleOpenConnection(); //need to start when open again with existing order number
            setOrderIDPage(true);
          } else {
            setOrderNumber("");
            setOrderLoading(false);
            setOrderIDPage(false);
          }
        });
      }
      setMinimizeModalState(false);
      props.handleMinimize(false);
    }
  }, [props.openModal]);

  useEffect(() => {
    setConnectAgain(props.openModal);
    handleSnackBar(false, false);
  }, [props.openModal]);

  useEffect(() => {
    return () => {
      if (socketConnected) {
        socketConnected.disconnect();
      }
    };
  }, []);

  const handleCloseModal = () => {
    if (orderIDPage && orderId != "") {
      setStopOrder(true);
    } else {
      setStopOrder(false);
      setOrderId("");
      setOrderNumber("");
      setOrderIDPage(false);
      props.handleClose();
    }
  };

  const handleStopConnection = () => {
    handleViewSubmit("stop");
    setConnectAgain(false);
    clearInterval(connectionCheckInterval);
    clearInterval(syncEventMessageLogInterval);
    // connectionCheckInterval = null;
    console.log("cleared connectionCheckInterval:", connectionCheckInterval);
    if (expandEnabled) {
      document.exitFullscreen();
      setExpandEnabled(false);
    }
    setDataV2Events({});
    setDatas(null);
    setFrameScanned(false);
    props.handleClose();

    setOrderId("");
    setOrderNumber("");
    setOrderIDPage(false);
    setStopOrder(false);
    if (socketConnected) {
      socketConnected.disconnect();
    }
  };

  const handleStopOrder = () => {
    setStopOrder(false);
  };

  const handleExpand = () => {
    if (!expandEnabled) {
      setExpandEnabled(true);
      document.body.requestFullscreen();
    } else {
      setExpandEnabled(false);
      document.exitFullscreen();
    }
  };

  const handleSnackBar = (open, error) => {
    setErrorSnack(error);
    setOpenSnack(open);
  };

  const handleViewSubmit = async (type) => {
    const access_token = auth?.access_token;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let body = {
      order_number: orderId,
    };
    let apiResponse;
    try {
      const response = await axiosPrivate.post(
        `/defect-inspection/v1/new_order?action=${type}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Time-Zone": `${timeZone}`,
          },
          withCredentials: false,
        }
      );
      setSnackMessage(response.data.message);
      apiResponse = response.data;
      handleSnackBar(true, false);
      setAuth((prev) => {
        return {
          ...prev,
          response: response.data,
        };
      });
    } catch (err) {
      apiResponse = err.response;
      setSnackMessage(err.response.data.message);
      handleSnackBar(true, true);
    }
    console.log("resps", apiResponse);
    return apiResponse;
  };

  const handleMinimize = () => {
    setMinimizeModalState(true);
    props.handleClose();
    props.handleMinimize(true);
  };

  const handleCreateOrder = () => {
    setEnableSubmit(false);
    setOrderLoading(true);
    handleViewSubmit("start").then((data) => {
      console.log(data);
      if (data.status != 400) {
        handleOpenConnection();
        setOrderNumber(orderId);
        setOrderIDPage(true);
        setTimeout(() => {
          setOrderLoading(false);
        }, 2000);
      }
    });
  };

  const handleTextChange = (value) => {
    if (value.length) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
    setOrderId(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const syncEventLogMessage = async () => {
    console.log("syncing event message...");
    const access_token = auth?.access_token;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let apiResponse;
    try {
      const response = await axiosPrivate.get(
        `/defect-inspection/v1/detect/message_log`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Time-Zone": `${timeZone}`,
          },
          withCredentials: false,
        }
      );
      apiResponse = response.data;
      if (apiResponse.hasOwnProperty("message_log")) {
        let data = apiResponse.message_log;
        if (data && data.hasOwnProperty("is_event_started")) {
          if (data?.is_frame_scanned) {
            console.log("frame_scanned");
            setDatas(data);
            setFrameScanned(true);
            if (data?.is_event_started) {
              let datav2 = data.datav2;
              if (Object.keys(datav2).includes("defective")) {
                setDataV2Events(data.datav2);
                setLoading(false);
              } else {
                setLoading(true);
                setDataV2Events({});
              }
            } else {
              setLoading(true);
              setDataV2Events({});
            }
          } else {
            setDatas(data);
            setDataV2Events({});
            setFrameScanned(false);
          }
        } else {
          setDataV2Events({});
        }
      }
    } catch (err) {
      console.log("Error in syncEventLogMessage:", err);
    }
  };

  return (
    <Dialog
      open={props.openModal}
      onClose={handleCloseModal}
      fullScreen={expandEnabled}
      fullWidth
      maxWidth="lg"
      BackdropProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          backdropFilter: "blur(1.5px)",
        },
      }}
      PaperProps={{
        style: {
          overflow: "hidden",
          minHeight: "600px",
          background: backgroundColor,
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            marginLeft: "12px",
          }}
        >
          {/* <h3
            onClose={handleCloseModal}
            style={{
              padding: "10px",
              color: "white",
              flex: 0.3,
              textAlign: "center",
            }}
          >
            Real Time Events
          </h3> */}
        </div>
        <div>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleExpand}
            aria-label="close"
            style={{
              width: "24px",
              display: "flex",
              float: "left",
              padding: "25px",
              marginRight: "12px",
              marginTop: "16px",
              color: iconColor,
            }}
          >
            {expandEnabled ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
          </IconButton>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{
              width: "24px",
              display: "flex",
              float: "right",
              padding: "25px",
              marginRight: "12px",
              marginTop: "16px",
              color: iconColor,
            }}
          >
            <CloseIcon />
          </IconButton>
          {orderIDPage && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="close"
              onClick={handleMinimize}
              style={{
                width: "24px",
                display: "flex",
                float: "right",
                padding: "25px",
                marginRight: "12px",
                marginTop: "16px",
                color: iconColor,
              }}
            >
              <HorizontalRule />
            </IconButton>
          )}
        </div>
      </div>
      <DialogContent style={{ display: "flex", padding: "0px" }}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "-10%",
          }}
        >
          {!orderIDPage ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3>Enter Order Number</h3>
              <TextField
                label="Order Number"
                style={{ margin: "8px" }}
                value={orderId}
                onChange={(e) => handleTextChange(e.target.value)}
              ></TextField>
              <Button
                variant="contained"
                onClick={handleCreateOrder}
                disabled={!enableSubmit}
              >
                Submit
              </Button>
            </div>
          ) : (
            <>
              {orderLoading ? (
                <CircularProgress></CircularProgress>
              ) : (
                <>
                  {isFrameScanned ? (
                    <>
                      {datas?.is_event_started ? (
                        <>
                          {!isLoading ? (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "start",
                                flexDirection: "column",
                                textAlign: "start",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "40px",
                                  fontStyle: "bold",
                                  color: "white",
                                  fontWeight: 700,
                                  padding: "20px 5px",
                                  textAlign: "start",
                                  marginLeft: "10%",
                                }}
                              >
                                <span style={{ textTransform: "capitalize" }}>
                                  {dataV2Events?.title}
                                </span>{" "}
                                [{datas.order_num} / {datas.detection_id}]
                              </div>
                              <div
                                style={{
                                  fontSize: "50px",
                                  color: "Black",
                                  fontWeight: 700,
                                  textAlign: "start",
                                  position: "relative",
                                  marginLeft: "10%",
                                }}
                              >
                                {dataV2Events?.message
                                  ?.split("$$")
                                  .map((msg, index) => (
                                    <p
                                      key={index}
                                      style={{ padding: 0, margin: 0 }}
                                    >
                                      {msg}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                height: "100%",
                                height: "360px",
                                width: "100%",
                              }}
                            >
                              <CircularProgress />
                              <div
                                style={{ padding: "10px", textAlign: "center" }}
                              >
                                New Frame : <span>{datas.detection_id}</span>
                                <p>Processing received frame.</p>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                            height: "360px",
                            width: "100%",
                          }}
                        >
                          <CircularProgress />
                          <div style={{ padding: "10px", textAlign: "center" }}>
                            New Frame : <span>{datas.detection_id}</span>
                            <p>Start moving the frame now.</p>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        height: "100%",
                        height: "360px",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        Waiting For New Frame. Please scan the barcode.
                      </div>
                    </div>
                  )}
                </>
              )}
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: "10px",
                  padding: "0px 10px",
                }}
              >
                <p
                  style={{
                    fontSize: "14px",
                    float: "left",
                    textTransform: "uppercase",
                  }}
                >
                  Current Order Number :{" "}
                  <span style={{ fontWeight: "700", fontSize: "16px" }}>
                    {orderNumber}
                  </span>
                </p>
                <Button
                  style={{
                    width: "200px",
                    float: "right",
                    background: "#00000000",
                    border: "3px solid #000",
                    color: "black",
                  }}
                  variant="contained"
                  onClick={() => setStopOrder(true)}
                >
                  Stop the Order
                </Button>
                {dataV2Events?.message?.split("$$").length == 4 ? (
                  <Button
                    style={{
                      width: "400px",
                      float: "right",
                      background: "#00000000",
                      // border: "3px solid #000",
                      color: "white",
                      marginRight: "20px",
                    }}
                    variant="contained"
                    disabled
                  >
                    <CircularProgress
                      style={{ color: "white", fontSize: "12px" }}
                    />{" "}
                    <span style={{ marginLeft: "15px" }}>
                      Scan new frame to continue
                    </span>
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
        <Dialog
          open={stopOrder}
          onClose={handleStopOrder}
          fullScreen={expandEnabled}
          maxWidth="lg"
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              backdropFilter: "blur(1.5px)",
            },
          }}
          PaperProps={{
            style: {
              overflow: "hidden",
              height: "35%",
              width: "35%",
              background: "white",
            },
          }}
        >
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <h3 style={{ margin: "auto", textAlign: "center" }}>
                Are you sure, want to stop the order?
              </h3>
              <div style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  style={{ background: "red" }}
                  onClick={() => setStopOrder(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: "12px", background: "green" }}
                  onClick={handleStopConnection}
                >
                  Yes
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Snackbar
          open={openSnack}
          autoHideDuration={4000}
          message={snackMessage}
          action={action}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={!errorSnack ? "success" : "error"}
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
};

export default RealEventsModal;
